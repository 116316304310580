import { AnalyticsBrowser } from '@segment/analytics-next'
import { isSSR } from '../../utils/ssrUtils.js'
import { isPPV } from '../../domain/center.js'
import { SponsorTier } from '@/types/models/Center.model'
import { stripNonNumeric } from '@/utils/text'
import qs from 'qs'
import { isNonEmptyString } from '@/utils/checks'
import * as sessionReplay from '@amplitude/session-replay-browser'
import { getCookieByName } from '@/utils/cookie'

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.GATSBY_SEGMENT_KEY || 'K0gzBza21a8TAcfOWRtGk2B2elXlRRzh'
})

type SegmentCenterClick = {
  center: string
  breadcrumbs: string
  position?: number
  positionInElement?: number
  sponsor_tier?: SponsorTier
  template: string
  zone: string
  action?: string
}

type SegmentCenterImpression = {
  center: string
  breadcrumbs: string
  position?: number
  positionInElement?: number
  sponsor_tier?: SponsorTier
  template: string
  zone: string
}

export const initTracking = async () => {
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })

  const deviceId = queryParams.deviceId || ''

  if (isNonEmptyString(deviceId)) {
    await analytics.setAnonymousId(deviceId)
  }

  if (isNonEmptyString(process.env.GATSBY_AMPLITUDE_KEY)) {
    const user = await analytics.user()
    const storedSessionId = getCookieByName('amp_session_id') || 0

    sessionReplay.init(process.env.GATSBY_AMPLITUDE_KEY, {
      sessionId: storedSessionId,
      deviceId: user.anonymousId() || undefined,
      sampleRate: 0.04
    })
  }
}

const useSegment = () => {
  return {
    feed: {
      impression: (data: SegmentCenterImpression) => {
        analytics.track('Center Impression', data)
      },
      click: (data: SegmentCenterClick) => {
        analytics.track('Center Click', data)
      }
    },
    profilePage: {
      inquirySubmitted: (data: { slug: string; verify_insurance: boolean }) => {
        analytics.track('Treatment Inquiry', {
          ...data
        })
      },
      ppv: (data: {
        title: string
        slug: string
        br: Float32Array
        sponsor_tier: SponsorTier
      }) => {
        if (!isSSR() && isPPV(data)) {
          analytics.track('PPV', data)
        }
      },
      outboundWebsiteLink: (data: { href: string }) => {
        analytics.track('Outbound_Website_Click', {
          ...data
        })
      }
    },
    verifyBenefits: {
      opened: (data: Partial<Record<string, unknown>>) => {
        analytics.track('Verify Benefits Form', { ...data })
      },
      submitted: (data: Partial<Record<string, unknown>>) => {
        analytics.track('Benefits form submitted', { ...data })
      },
      externalLink: (data: Partial<Record<string, unknown>>) => {
        analytics.track('VOB', { ...data })
      }
    },
    ui: {
      click: (eventName, data: Partial<Record<string, unknown>> = {}) => {
        analytics.track(eventName, { ...data })
      },
      callCta: (data: { href: string }) => {
        analytics.track('Call', { href: stripNonNumeric(data.href) })
      },
      whatsappCta: (data: { number: string }) => {
        analytics.track('WhatsApp_Call', { href: stripNonNumeric(data.number) })
      }
    },
    exploreDestination: {
      linkClick: () => {
        analytics.track('explore_link')
      },
      tabToggle: (data: { tab: string }) => {
        analytics.track('explore_toggle', data)
      }
    }
  }
}

export default useSegment
